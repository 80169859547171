import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Tooltip,
  message,
  notification,
} from "antd";
import { useRecoilState } from "recoil";
import Table, { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, deleteMethod, get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { DeleteOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate, useParams } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";
import { MenuState } from "../../../lib/stores/menuId";
import DownloadButtons from "../../../lib/utils/DownloadFiles/page";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./page.scss";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "updatedAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }
  if (key === "variantSize") {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }
  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const { Search } = Input;

const MenuItem: React.FC = () => {
  const { menuId } = useParams();
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };
  const [menuList, setMenuList] = useState([]);
  const [selectMenu, setSelectMenu] = useState(null);
  const [dynamicMenuId, setMenuId] = useRecoilState(MenuState);
  const [selectedMenuName, setSelectedMenuName] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedMenuItemsIds, setSelectedMenuItemsIds] = useState([]);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // Track selected rows
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true); // Manage delete button state
  // State for filtered data

  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataModal, setFilteredDataModal] = useState([]);
  const [editSortId, setEditSortId] = useState({}); // For storing edited sortIds
  const [editTimeout, setEditTimeout] = useState(null); // Timeout for debouncing API calls
  const [editableRow, setEditableRow] = useState(null); // To track the row being edited
  const [menuItems, setMenuItems] = useState([]);

  // useEffect(())
  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/menu" },
      { label: "Menu", href: "/menu" },
      { label: "Menu Items", href: `/menu-item-price/${menuId}` },
    ]);
  }, [setBreadCrumb]);

  const handleEdit = (record: any) => {
    navigate(`/menu-item-price/${record.itemVariantId}`);
  };

  const handleDeleteConfirmation = async (
    menuId?: string,
    variantId?: string
  ) => {
    try {
      if (variantId) {
        const response = await deleteMethod(
          `${apiEndpoints.menuitem}/${menuId}/${variantId}`
        );
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          // await fetchMenus();
          await fetchMenuItems(menuId);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const [isAllSelected, setIsAllSelected] = useState(false); // State for header checkbox

  const handleSelectAll = (checked) => {
    setSelectedIds(checked ? menuItems?.map((item) => item.itemVariantId) : []);
  };

  const handleCheckboxChange = (itemVariantId) => {
    setSelectedIds((prev) =>
      prev.includes(itemVariantId)
        ? prev.filter((id) => id !== itemVariantId) // Compare directly with the ID
        : [...prev, itemVariantId]
    );
  };

  useEffect(() => {
    if (selectedIds?.length > 0) {
      setIsDeleteButtonDisabled(true);
    } else {
      setIsDeleteButtonDisabled(false);
    }
  }, [selectedIds]);

  // Function to handle sortId change manually by user
  const handleSortIdChange = (value, record) => {
    const updatedSortId = parseInt(value, 10); // Ensure the value is a number
    const updatedData = filteredData?.map((item) => {
      if (item.itemVariantId === record.itemVariantId) {
        // Update the sortId for the specific variantId
        return {
          ...item,
          sortId: updatedSortId ? Number(updatedSortId) : null,
        };
      }
      return item;
    });
    setFilteredData(updatedData); // Update the table data
  };

  // Function to check if the sortId sequence is continuous
  const validateSortIdSequence = (data) => {
    const sortIds = data?.map((item) => item.sortId).sort((a, b) => a - b);
    for (let i = 1; i <= sortIds.length; i++) {
      if (sortIds[i - 1] !== i) {
        return false; // If any number is skipped, the sequence is invalid
      }
    }
    return true; // Sequence is valid if no numbers are skipped
  };

  // const columns: any = [
  //   {
  //     title: "", // First column for the drag icon
  //     dataIndex: "title",
  //     key: "title",
  //     render: () => <DragOutlined className="draggable" />, // Add DragOutlined icon here
  //     width: 20,
  //   },
  //   {
  //     title: (
  //       <Checkbox
  //         checked={isAllSelected}
  //         onChange={(e) => handleSelectAll(e.target.checked)}
  //       />
  //     ),
  //     key: "select",
  //     render: (record) => (
  //       <Checkbox
  //         checked={selectedMenuItemsIds.includes(record.itemVariantId)}
  //         onChange={() => handleCheckboxChange(record.itemVariantId)}
  //       />
  //     ),
  //     width: 20,
  //   },

  //   {
  //     title: "Variant ID",
  //     dataIndex: "itemVariantId",
  //     key: "itemVariantId",
  //     sorter: (a: any, b: any) =>
  //       (a?.itemVariantId?.toString() ?? "")
  //         .toLowerCase()
  //         .localeCompare((b?.itemVariantId?.toString() ?? "").toLowerCase()),
  //     width: 30,
  //     render: renderStatus,
  //   },

  //   {
  //     title: "Variant Name",
  //     dataIndex: "variantName",
  //     key: "variantName",
  //     sorter: (a: any, b: any) =>
  //       (a?.variantName?.toString() ?? "")
  //         .toLowerCase()
  //         .localeCompare((b?.variantName?.toString() ?? "").toLowerCase()),
  //     width: 100,
  //     render: renderStatus,
  //   },

  //   {
  //     title: "Variant Size",
  //     dataIndex: "variantSize",
  //     key: "variantSize",
  //     sorter: (a: any, b: any) =>
  //       (a?.variantSize?.toString() ?? "")
  //         .toLowerCase()
  //         .localeCompare((b?.variantSize?.toString() ?? "").toLowerCase()),
  //     width: 100,
  //     // render: renderStatus,
  //     render: (value) => renderStatus(value, "variantSize"),
  //   },
  //   {
  //     title: "Price",
  //     dataIndex: "unitPrice",
  //     key: "unitPrice",
  //     sorter: (a: any, b: any) =>
  //       (a?.unitPrice?.toString() ?? "")
  //         .toLowerCase()
  //         .localeCompare((b?.unitPrice?.toString() ?? "").toLowerCase()),
  //     width: 100,
  //     render: renderStatus,
  //   },
  //   {
  //     title: "Sort Id",
  //     dataIndex: "sortId",
  //     key: "sortId",
  //     sorter: (a: any, b: any) => a.sortId - b.sortId,
  //     width: 30,
  //     render: (value: any, record: any) =>
  //       editableRow === record.menuId ? (
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Input
  //             value={record?.sortId}
  //             onChange={(e) => handleSortIdChange(e.target.value, record)}
  //             style={{ width: 80 }}
  //           />
  //           {/* <Button
  //             icon={<SaveOutlined />}
  //             type="primary"
  //             onClick={handleSaveAll} // Save individual row
  //             style={{ marginLeft: 8 }}
  //           ></Button> */}
  //         </div>
  //       ) : (
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //           }}
  //         >
  //           <div>{value}</div>
  //           {/* <Button
  //             icon={<EditOutlined />}
  //             onClick={() => setEditableRow(record.menuId)} // Edit mode for the specific row
  //             style={{ marginLeft: 8 }}
  //           ></Button> */}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: "Item ID",
  //     dataIndex: "itemId",
  //     key: "itemId",
  //     sorter: (a: any, b: any) =>
  //       (a?.itemId?.toString() ?? "")
  //         .toLowerCase()
  //         .localeCompare((b?.itemId?.toString() ?? "").toLowerCase()),
  //     width: 50,
  //     render: renderStatus,
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updatedAt",
  //     key: "updatedAt",
  //     sorter: (a: any, b: any) =>
  //       (a?.updatedAt?.toString() ?? "")
  //         .toLowerCase()
  //         .localeCompare((b?.updatedAt?.toString() ?? "").toLowerCase()),
  //     width: 50,
  //     render: (value) => renderStatus(value, "updatedAt"),
  //   },
  //   // {
  //   //   title: "Action",
  //   //   key: "operation",
  //   //   fixed: "right",
  //   //   width: 30,
  //   //   render: (record) => (
  //   //     <div
  //   //       style={{
  //   //         display: "flex",
  //   //         flexDirection: "row",
  //   //         alignItems: "center",
  //   //       }}
  //   //     >
  //   //       <Tooltip title="Edit">
  //   //         <EditOutlined onClick={() => handleEdit(record)} />
  //   //       </Tooltip>
  //   //     </div>
  //   //   ),
  //   // },
  // ];

  const fetchMenus = async () => {
    try {
      const response = await get(apiEndpoints.menus);
      if (response.status === 200) {
        setMenuList(response?.data);

        // Find the object with the matching menuId
        const matchedMenu = response.data.find(
          (item) => item.menuId === Number(menuId)
        );

        // Get the menuName or return 'Unknown' if not found
        const menuName = matchedMenu ? matchedMenu.menuName : "Unknown";

        setSelectedMenuName(menuName);
        // setSelectedMenuName(response?.data[0]?.menuName);
        if (dynamicMenuId) {
          setMenuId(dynamicMenuId);
          setSelectMenu(dynamicMenuId);
        } else {
          setMenuId(response?.data[0]?.menuId);
          setSelectMenu(response?.data[0]?.menuId);
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const fetchVariants = async () => {
    try {
      const response = await get(apiEndpoints.variants);
      if (response.status === 200) {
        setDataSource(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    fetchMenus();
    fetchVariants();
  }, []);

  const handleChange = async (value) => {
    await fetchMenuItems(value);
    setSelectMenu(value);
    const menuName: any = menuList.filter((item) => item.menuId === value);
    setSelectedMenuName(menuName[0]?.menuName);
    // setMenuId(value);
    // localStorage.setItem("menuId", value);
  };

  const handleDeleteMenuItems = () => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete All Items.",
      async () => {
        const response = await deleteMethod(
          `${apiEndpoints.menuitem}/${menuId}`
        );
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted All Menu Items",
          });
          // await fetchMenuItems(selectMenu);
          await fetchMenuItems(menuId);
        }
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columnsModal = [
    {
      title: "Select",
      dataIndex: "select",
      render: (_, record) => (
        <Checkbox onChange={() => handleCheckboxClick(record.variantId)} />
      ),
    },
    {
      title: "Variant Id",
      dataIndex: "variantId",
      key: "variantId",
    },
    {
      title: "Variant Name",
      dataIndex: "variantName",
      key: "variantName",
    },
    {
      title: "Variant Size",
      dataIndex: "variantSize",
      key: "variantSize",
      render: (value) => renderStatus(value, "variantSize"),
    },
  ];

  const fetchMenuItems = async (id) => {
    try {
      const response = await get(`${apiEndpoints.menuitemsList}/${id}`);
      if (response?.status === 200) {
        setMenuItemsList(response?.data);
        setMenuItems(response?.data);
        setRowData(response?.data);
        // setFilteredData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    setFilteredData(menuItemsList);
  }, [menuItemsList]);

  useEffect(() => {
    setFilteredDataModal(dataSource);
  }, [dataSource]);

  useEffect(() => {
    fetchMenuItems(menuId);
  }, [menuId]);

  const handleCheckboxClick = async (id) => {
    // Add or remove selected id from the selectedIds state
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );

    // Get the current length of the menuItemsList
    const currentMenuItemsLength = menuItemsList?.length || 0;

    // Create the itemVariants array dynamically with the correct sortId
    const itemVariants = selectedIds?.map((selectedId, idx) => ({
      variantId: selectedId,
      sortId: currentMenuItemsLength + idx + 1, // Increment sortId dynamically
    }));

    // Add the newly selected id into the itemVariants array
    itemVariants.push({
      variantId: id,
      sortId: currentMenuItemsLength + selectedIds.length + 1,
    });

    setSelectedVariants(itemVariants);
  };

  const SubmitVairantstoMenuId = async () => {
    try {
      // Post the payload to the API
      const payLoad: any = {
        menuId: Number(menuId),
        itemVariants: selectedVariants,
      };
      const response = await post(apiEndpoints.menuitem, payLoad);
      if (response?.status === 200) {
        notification.success({
          message: "Success",
        });
        await fetchMenuItems(menuId);
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error posting data", error);
      notification.error({
        message: "Error",
      });
    } finally {
      setSelectedIds([]);
    }
  };

  const hadleAddVariant = () => {
    // Clear the selected checkboxes when the modal is opened
    setSelectedIds([]);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    // Clear the selected checkboxes when the modal is opened
    setSelectedIds([]);
    setIsModalVisible(false);
  };

  const handleDelete = async () => {
    if (isAllSelected) {
      handleDeleteMenuItems();
    } else {
      for (const ids of selectedMenuItemsIds) {
        await handleDeleteConfirmation(menuId, ids);
      }
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermModal, setSearchTermModal] = useState("");

  useEffect(() => {
    if (searchTerm) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();

      const filtered = menuItemsList.filter((row) => {
        // Iterate over each key in the row
        return Object.keys(row).some((key) => {
          // Ensure the value is not undefined or null
          if (row[key] != null) {
            // Convert the value to a lowercased string for case-insensitive comparison
            const valueAsString = String(row[key]).toLowerCase();
            return valueAsString.includes(lowercasedSearchTerm);
          }
          return false;
        });
      });

      setFilteredData(filtered);
    } else {
      setFilteredData(menuItemsList);
    }
  }, [searchTerm, menuItemsList]);

  useEffect(() => {
    if (searchTerm) {
      const lowercasedSearchTerm = searchTermModal.toLowerCase();

      const filtered = dataSource.filter((row) => {
        // Iterate over each key in the row
        return Object.keys(row).some((key) => {
          // Ensure the value is not undefined or null
          if (row[key] != null) {
            // Convert the value to a lowercased string for case-insensitive comparison
            const valueAsString = String(row[key]).toLowerCase();
            return valueAsString.includes(lowercasedSearchTerm);
          }
          return false;
        });
      });

      setFilteredDataModal(filtered);
    } else {
      setFilteredDataModal(dataSource);
    }
  }, [searchTermModal, dataSource]);

  const handleSortAndUpdate = async (dragIndex, dropIndex) => {
    const reorderedData = Array.from(menuItems);
    const [movedItem] = reorderedData.splice(dragIndex, 1);
    reorderedData.splice(dropIndex, 0, movedItem);
    const updatedData = reorderedData?.map((item, index) => ({
      ...item,
      sortId: index + 1,
    }));

    setMenuItems(updatedData);
    try {
      const response = await post(apiEndpoints.menuitem, {
        menuId: Number(menuId),
        itemVariants: updatedData?.map(({ itemVariantId, sortId }) => ({
          variantId: itemVariantId,
          sortId,
        })),
      });
      if (response?.status === 200) {
        notification.success({ message: "Sort order updated successfully" });
        fetchMenuItems(menuId);
      }
    } catch (error) {
      console.error("Error posting data", error);
      notification.error({ message: "Error updating sort order." });
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    handleSortAndUpdate(source.index, destination.index);
  };

  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Sorting function
  const handleSort = (column: string) => {
    let newDirection: any = sortDirection === "asc" ? "desc" : "asc";
    if (sortColumn !== column) {
      newDirection = "asc"; // Default to ascending when sorting a new column
    }

    const sortedData = [...menuItems].sort((a, b) => {
      const aValue = a[column as keyof typeof a]; // Get the value by column
      const bValue = b[column as keyof typeof b]; // Get the value by column

      if (typeof aValue === "string" && typeof bValue === "string") {
        return newDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return newDirection === "asc" ? aValue - bValue : bValue - aValue;
      }

      return 0;
    });

    setMenuItems(sortedData);
    setSortColumn(column);
    setSortDirection(newDirection);
  };
  const renderSortIcon = (column: string) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? "▲" : "▼";
    }
    return "▲▼"; // Show both arrows when not sorted
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Pagination logic to show data for the current page
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = menuItems.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  return (
    <div className="menu-item-price-page">
      <Modal
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label>Select Variants</label>
            <Button
              type="primary"
              onClick={SubmitVairantstoMenuId}
              style={{ marginRight: "32px" }}
            >
              Submit
            </Button>
          </div>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Table dataSource={filteredDataModal} columns={columnsModal} />
      </Modal>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Menu :</label>
          {/* <Select disabled placeholder="Select Menu" value={selectMenu}>
            {menuList?.map((menu) => (
              <Select.Option key={menu?.menuId} value={menu?.menuId}>
                {menu.menuName}
              </Select.Option>
            ))}
          </Select> */}
          <label style={{ fontWeight: "bold" }}>{selectedMenuName}</label>
        </div>
        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <div>
              <label style={{ marginRight: "10px" }}>Quick Filter: </label>
              <Input
                style={{ width: "20rem" }}
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </Col>
          <Col>
            <div style={{ display: "flex" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={hadleAddVariant}
                style={{ marginRight: 8 }}
              >
                Add
              </Button>

              <DownloadButtons tableData={filteredData} name="Menu Items" />

              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                icon={<DeleteOutlined />}
                onClick={handleDelete}
                disabled={!isDeleteButtonDisabled} // Disable if no rows are selected
              >
                Delete
              </Button>
            </div>
          </Col>
        </Row>
        {/* <Table dataSource={filteredData} columns={columns} /> */}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: any) => (
              <div className="table-container">
                <table
                  className="ant-table"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <thead className="ant-table-thead">
                    <tr>
                      <th></th>
                      <th>
                        <Checkbox
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                      </th>
                      {/* <th>Variant ID</th>
                    <th>Variant Name</th>
                    <th>Variant Size</th>
                    <th>Price</th>
                    <th>Sort ID</th>
                    <th>Updated At</th> */}
                      <th onClick={() => handleSort("itemVariantId")}>
                        Variant ID {renderSortIcon("itemVariantId")}
                      </th>
                      <th onClick={() => handleSort("variantName")}>
                        Variant Name {renderSortIcon("variantName")}
                      </th>
                      <th onClick={() => handleSort("variantSize")}>
                        Variant Size {renderSortIcon("variantSize")}
                      </th>
                      {/* <th onClick={() => handleSort("unitPrice")}>
                        Unit Price {renderSortIcon("unitPrice")}
                      </th> */}
                      <th onClick={() => handleSort("sortId")}>
                        Sort ID {renderSortIcon("sortId")}
                      </th>
                      <th onClick={() => handleSort("updatedAt")}>
                        Updated At {renderSortIcon("updatedAt")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {menuItems?.map((item, index) => (
                      <Draggable
                        key={item.itemVariantId}
                        draggableId={item.itemVariantId.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="ant-table-row no-data"
                          >
                            <td>
                              <MenuOutlined style={{ cursor: "pointer" }} />
                            </td>
                            <td>
                              <Checkbox
                                checked={selectedIds.includes(
                                  item.itemVariantId
                                )}
                                onChange={() =>
                                  handleCheckboxChange(item.itemVariantId)
                                }
                              />
                            </td>
                            <td>{item.itemVariantId}</td>
                            <td>{item.variantName}</td>
                            <td>{item.variantSize}</td>
                            {/* <td>{item.unitPrice}</td> */}
                            <td>{item.sortId}</td>
                            <td>
                              {new Date(item.updatedAt).toLocaleDateString()}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* Pagination Component */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          {menuItems?.length > 0 ? (
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={menuItems?.length}
              onChange={handlePageChange}
              showSizeChanger
            />
          ) : (
            <div
              style={{
                margin: "50%",
                width: "200px",
              }}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default MenuItem;
