import React from "react";
import { Breadcrumb } from "antd";
import "./page.css";
interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface DynamicBreadcrumbProps {
  items: BreadcrumbItem[];
}

const DynamicBreadcrumb: React.FC<DynamicBreadcrumbProps> = ({ items }) => {
  return (
    <Breadcrumb style={{ margin: "16px 16px" }}>
      {items?.map((item, index) => (
        <Breadcrumb.Item
          key={item.label + index}
          href={item.href}
          className={index === items.length - 1 ? "last-item" : undefined}
        >
          {item.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default DynamicBreadcrumb;
