import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import { Form, Button, message, notification, Row, Col } from "antd";
import FormBuilder from "antd-form-builder";
// import { companyState } from "../../../lib/stores/companyId";
import "./page.css";

const PricePlanForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [roles, setRoles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Price Plan", href: "/price-plans" },
      {
        label: id ? "Edit Price plan" : "Add Price plan",
        href: id ? `/price-plans/${id}` : "/price-plans/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      const payLoad = {
        companyId: id,
      };
      const response = await post(apiEndpoints.getCompanyMasterId, payLoad);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("editCompany")) {
      fetchById();
    }
  }, [location]);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await get(apiEndpoints.getAllCompanies);
        if (response && response.status === 200 && response.data) {
          setCompanyDropdown(response.data);
        }
        const dropdownResponse = await get(apiEndpoints.getAllUserRoles);
        if (dropdownResponse.status === 200) {
          setRoles(dropdownResponse?.data);
        }
      } catch (error) {
        console.log("Error fetching companies:", error);
      }
    };

    fetchOrg();
  }, []);

  const onFinish = async (values: any) => {
    try {
      const userRoles: string[] = [];
      if (values.role.length > 0) {
        values.role?.forEach((item: any) => {
          let role;
          switch (item) {
            case "ROLE_ADMIN":
              role = "admin";
              break;
            case "ROLE_ZONAL":
              role = "zonal";
              break;
            case "ROLE_MASTER":
              role = "master";
              break;
            case "ROLE_OPERATOR":
              role = "operator";
              break;
            case "ROLE_USER":
              role = "user";
              break;
            case "ROLE_OEM":
              role = "oem";
              break;
            case "ROLE_STOCK":
              role = "stock";
              break;
            default:
              role = null;
              break;
          }
          if (role) {
            userRoles.push(role);
          }
        });
      }
      const modifiedValues = {
        ...values,
        active: values.active ? 1 : 0,
        role: userRoles,
      };

      // Now use modifiedValues for submission
      const payLoad: any = { ...modifiedValues };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "updateLocationMaster",
        addDevice: "registerUser",
      };
      const endPoint: any = id ? endPoints.updateDevice : endPoints.addDevice;
      const method = id ? put : post;
      const response: any = await method(apiEndpoints[`${endPoint}`], payLoad);
      if (response.status === 200) {
        notification.success({
          message: id ? "Updated Successfully" : "Created Successfully",
        });
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    message.error("Please fill in all fields");
  };

  const companyFormMeta: any = [
    {
      name: "planName",
      label: "Plan Name",
      type: "input",
      placeholder: "Enter Plan Name",
      rules: [
        { required: true, message: "This field is required" },
        { min: 4, message: "Name must be at least 4 characters" },
      ],
    },
    {
      name: "planSlabCount",
      label: "Plan Slab Count",
      type: "input",
      inputType: "number",
      placeholder: "Enter plan Slab Count",
    },
    {
      name: "planSlabPrice",
      label: "Plan Slab Price",
      type: "input",
      inputType: "number",
      placeholder: "Enter Plan Slab Price",
    },
    {
      name: "additionalSalePrice",
      label: "Additional Sale Price",
      type: "input",
      inputType: "number",
      placeholder: "Enter Additional Sale Price",
    },
  ];

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          style={{ width: "100%" }}
          className="priceplan-form"
        >
          {/* <FormBuilder meta={companyFormMeta} form={form} /> */}
          <Row gutter={16}>
            {companyFormMeta?.map((item: any, index: any) => (
              <Col span={8} key={index}>
                <FormBuilder meta={[item]} form={form} />
              </Col>
            ))}
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {id ? "Update Price plan" : "Add Price plan"}
            </Button>
            <Button onClick={() => navigate("/price-plans")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default PricePlanForm;
