import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
  Spin,
  Input,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";
import "./page.css";

const MachinesForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [statesDropdown, setStatesDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [machineTypesDropdown, setMachineTypesDropdown] = useState([]);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/machines" },
      { label: "Machines", href: "/machines" },
      {
        label: machineId ? "Edit Machine" : "Add Machine",
        href: machineId ? `/machines/${machineId}` : "/machines/add",
      },
    ]);
  }, [setBreadCrumb]);

  const { machineId } = useParams();

  const fetchById = async () => {
    try {
      setIsLoadingData(true);
      const payLoad = {
        companyId: id,
      };
      // replace endpoint with location id endpoint to get details
      const response = await get(
        `${apiEndpoints.MachinesBymachineId}/${machineId}`
        // payLoad
      );
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          // active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
        setTimeout(() => {
          setIsLoadingData(false);
        }, 1500);
      }
    } catch (error) {
      console.log(error, "error");
      setTimeout(() => {
        setIsLoadingData(false);
      }, 1500);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("editMachines")) {
      fetchById();
    } else {
      setIsLoadingData(false);
    }
  }, [location]);

  useEffect(() => {
    handleData();
  }, []);
  const [paymentSettings, setPaymentSettings] = useState([]);
  const handleData = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const response = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (response.status === 200) {
        setLocationsDropdown(response?.data);
      }
      const companyDevices = await get(apiEndpoints.getAllMachineTypes);
      if (companyDevices.status === 200) {
        setMachineTypesDropdown(companyDevices?.data);
      }
      const responseC = await get(apiEndpoints.getAllCompanies);
      if (responseC && responseC.status === 200 && responseC.data) {
        setCompanyDropdown(responseC.data);
      }
      const responsePayment = await get(
        apiEndpoints.getAllActivePaymentProviders
      );
      if (
        responsePayment &&
        responsePayment.status === 200 &&
        responsePayment.data
      ) {
        setPaymentSettings(responsePayment.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const onFinish = async (values: any) => {
    try {
      // const modifiedValues = {
      //   ...values,
      //   active: values.active ? 1 : 0,
      // };

      // Now use modifiedValues for submission
      const payLoad: any = { ...values, companyMasterId: values?.companyId };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "MachinesBymachineId",
        addDevice: "addMachineMaster",
      };
      const endPoint: any = machineId
        ? endPoints.updateDevice
        : endPoints.addDevice;
      const method = machineId ? put : post;
      const response: any = await method(
        machineId
          ? `${apiEndpoints[endPoint]}/${machineId}`
          : apiEndpoints[endPoint],
        payLoad
      );
      if (response.status === 200) {
        notification.success({
          message: machineId ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/machines");
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  const paymentModeDropdown = [
    { id: 0, value: "Regular with Payment" },
    { id: 1, value: "Skip Payment" },
  ];

  const eventControlDropdown = [
    { id: 0, value: "off-stop" },
    { id: 1, value: "can dispense" },
  ];

  const companyFormMeta: any = [
    {
      name: "companyId",
      label: "Company Name",
      type: "select",
      placeholder: "Select Company Name",
      rules: [{ required: true, message: "Select a Company Name!" }],
      widget: Select,
      onchange: (value: any) => {
        console.log(value, "value---");
      },
      widgetProps: {
        options: companyDropdown?.map((c: any) => ({
          value: c.companyId,
          label: c.companyName,
        })),
      },
    },
    {
      name: "machineName",
      label: "Name",
      type: "input",
      placeholder: "Enter Machine Name",
      rules: [
        { required: true, message: "This field is required" },
        { min: 4, message: "Name must be at least 4 characters" },
      ],
    },
    {
      name: "machineAddress",
      label: "Address",
      type: "input",
      placeholder: "Enter Machine Address",
      widgetProps: {
        maxLength: 16,
        minLength: 16,
      },
      rules: [
        { required: true, message: "This field is required" },
        {
          pattern: "^[0-9a-fA-F]*$",
          message: "Only A to F and 0 to 9 are allowed (16 digits Hexa)",
        },
      ],
    },
    {
      name: "locationId",
      label: "Location",
      type: "select",
      placeholder: "Select Location",
      rules: [{ required: true, message: "Select a Location!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: locationsDropdown?.map((c: any) => ({
          value: c.locationId,
          label: c.locationName,
        })),
      },
    },
    {
      name: "machineTypeId",
      label: "Type",
      type: "select",
      placeholder: "Select Type",
      rules: [{ required: true, message: "Select a Type!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: machineTypesDropdown?.map((c: any) => ({
          value: c.machine_type_id,
          label: c.machineTypeName,
        })),
      },
    },
    {
      name: "machineMode",
      label: "Machine Mode",
      type: "select",
      placeholder: "Select Machine Mode",
      rules: [{ required: true, message: "Select a Machine Mode!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: paymentModeDropdown?.map((c: any) => ({
          value: c.id,
          label: c.value,
        })),
      },
    },
    {
      name: "machineEventControl",
      label: "Event Control",
      type: "select",
      placeholder: "Select Event Control",
      rules: [{ required: true, message: "Select a Event Control!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: eventControlDropdown?.map((c: any) => ({
          value: c.id,
          label: c.value,
        })),
      },
    },
    {
      name: "paymentSettingsId",
      label: "Payment Settings",
      type: "select",
      placeholder: "Select Payment Settings",
      // rules: [{ required: true, message: "Select a Payment Settings!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: paymentSettings?.map((c: any) => ({
          value: c.paymentSettingsId,
          label: c.paymentProvider,
        })),
      },
    },
  ];

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {isLoadingData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100vw",
            }}
          >
            <Spin size={"large"} />
          </div>
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            initialValues={
              {
                /* Set initial values if any */
              }
            }
            className="company-form"
          >
            {/* <FormBuilder meta={companyFormMeta} form={form} /> */}
            <Row gutter={24}>
              {location.pathname.includes("editMachines") && (
                <Col span={8}>
                  <Form.Item name="machineId" label="Machine ID">
                    <Input disabled style={{ width: " 67%" }} />
                  </Form.Item>
                </Col>
              )}
              {companyFormMeta?.map((item: any, index: any) => (
                <Col span={8} key={index}>
                  <FormBuilder meta={[item]} form={form} />
                </Col>
              ))}
            </Row>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{ marginRight: "10px" }}
              >
                {machineId ? "Update" : "Add"}
              </Button>
              <Button onClick={() => navigate("/machines")}>Cancel</Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
};

export default MachinesForm;
