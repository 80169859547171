import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
  Switch,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  phoneNumberValidator,
} from "../../../lib/utils/form-validator";
// import { companyState } from "../../../lib/stores/companyId";
import "./page.css";
import { companyState } from "../../../lib/stores/companyId";

const UsersForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  // const [statesDropdown, setStatesDropdown] = useState([]);
  const [roles, setRoles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/users" },
      { label: "Users", href: "/users" },
      {
        label: id ? "Edit User" : "Add User",
        href: id ? `/users/${id}` : "/users/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      const payLoad = {
        companyId: id,
      };
      const response = await post(apiEndpoints.getCompanyMasterId, payLoad);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      fetchById();
    }
  }, [location]);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await get(apiEndpoints.getAllCompanies);
        if (response && response.status === 200 && response.data) {
          setCompanyDropdown(response.data);
        }
      } catch (error) {
        console.log("Error fetching companies:", error);
      }
    };

    fetchOrg();
  }, []);

  const handleCompanyChange = async (val) => {
    console.log(val, "0000");
    try {
      const payLoad = {
        companyId: val,
      };
      const dropdownResponse = await post(
        apiEndpoints.getRolesByCompanyId,
        payLoad
      );
      if (dropdownResponse.status === 200) {
        setRoles(dropdownResponse?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onFinish = async (values: any) => {
    try {
      const userRoles: string[] = [];
      if (values.role.length > 0) {
        values.role?.forEach((item: any) => {
          let role;
          switch (item) {
            case "ROLE_ADMIN":
              role = "admin";
              break;
            case "ROLE_RENTALSPACE":
              role = "rentalspace";
              break;
            case "ROLE_OPERATOR":
              role = "operator";
              break;
            case "ROLE_USER":
              role = "user";
              break;
            case "ROLE_OEM":
              role = "oem";
              break;
            case "ROLE_STOCK":
              role = "stock";
              break;
            case "ROLE_FRANCHISE":
              role = "franchise";
              break;
            default:
              role = null;
              break;
          }
          if (role) {
            userRoles.push(role);
          }
        });
      }
      const modifiedValues = {
        ...values,
        active: values.active ? 1 : 0,
        role: userRoles,
      };

      // Now use modifiedValues for submission
      const payLoad: any = { ...modifiedValues };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "updateLocationMaster",
        addDevice: "registerUser",
      };
      const endPoint: any = id ? endPoints.updateDevice : endPoints.addDevice;
      const method = id ? put : post;
      const response: any = await method(apiEndpoints[`${endPoint}`], payLoad);
      if (response.status === 200) {
        notification.success({
          message: id ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/users");
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  useEffect(() => {
    console.log(form, "form-");
  }, [form]);
  const companyFormMeta: any = [
    // {
    //   name: "companyId",
    //   label: "Company Name",
    //   type: "select",
    //   placeholder: "Select Company Name",
    //   rules: [{ required: true, message: "Select a Company Name!" }],
    //   widget: Select,
    //   onchange: (value: any) => {
    //     console.log(value, "value---");
    //   },
    //   widgetProps: {
    //     options: companyDropdown?.map((c: any) => ({
    //       value: c.companyId,
    //       label: c.companyName,
    //     })),
    //   },
    // },
    {
      name: "userName",
      label: <div className="label-as">User Name</div>,
      type: "input",
      placeholder: "Enter User Name",
      rules: [
        {
          validator: async (rule, value: string) => {
            try {
              await emailValidator(value, true);
            } catch (error) {
              throw new Error(error.message);
            }
          },
        },
      ],
    },
    {
      name: "firstName",
      label: "First Name",
      type: "input",
      placeholder: "Enter First Name",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "input",
      placeholder: "Enter Last Name",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "mobileNo",
      label: "Mobile Number",
      type: "input",
      placeholder: "Enter Mobile Number",
      widgetProps: {
        maxLength: 10,
      },
      rules: [
        {
          validator: async (rule, value: string) => {
            try {
              await phoneNumberValidator(value);
            } catch (error) {
              throw new Error(error.message);
            }
          },
        },
      ],
    },
    {
      name: "password",
      label: "Password",
      type: "input",
      placeholder: "Enter Password",
      rules: [{ required: true, message: "This field is required" }],
    },
    // {
    //   name: "active",
    //   label: "Active",
    //   render: (form: any) => (
    //     <Form.Item
    //       name="active"
    //       label="Active"
    //       valuePropName="checked"
    //       initialValue={false}
    //     >
    //       <Switch
    //         checkedChildren="Active"
    //         unCheckedChildren="InActive"
    //         onChange={(checked) => {
    //           // Convert the boolean to a string and set the form field value
    //           // const value = checked ? "1" : "0";
    //           form.setFieldsValue({ active: checked });
    //         }}
    //       />
    //     </Form.Item>
    //   ),
    // },
  ];

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          className="company-form"
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Company Name"
                name="companyId"
                rules={[{ required: true, message: "Please Select Company" }]}
              >
                <Select
                  onChange={handleCompanyChange}
                  // mode="multiple"
                  placeholder="Select Company"
                  style={{ width: "67%" }}
                  options={companyDropdown?.map((c: any) => ({
                    value: c.companyId,
                    label: c.companyName,
                  }))}
                />
              </Form.Item>
            </Col>
            {companyFormMeta?.map((item: any, index: any) => (
              <>
                <Col span={8} key={index}>
                  <FormBuilder meta={[item]} form={form} />
                </Col>
              </>
            ))}
            <Col span={8}>
              <Form.Item
                name="active"
                label="Active"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="InActive"
                  // onChange={(checked) => {
                  //   // Convert the boolean to a string and set the form field value
                  //   // const value = checked ? "1" : "0";
                  //   form.setFieldsValue({ active: checked });
                  // }}
                />
              </Form.Item>
            </Col>
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: "Please Select Roles" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select Roles"
                style={{ flex: 1, minWidth: "294px", maxWidth: "100%" }}
                options={roles?.map((c: any) => ({
                  value: c.name,
                  label: c.name,
                }))}
              />
            </Form.Item>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {id ? "Update User" : "Add User"}
            </Button>
            <Button onClick={() => navigate("/users")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default UsersForm;
