import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { companyState } from "../../../lib/stores/companyId";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import dayjs from "dayjs";
import ReactECharts from "echarts-for-react";
import { Card, Empty } from "antd";

const RevenueSummaryPie = () => {
  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>([]);

  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchData = async () => {
      try {
        const response = await post(apiEndpoints.getRevenueByStoreId, payLoad);
        if (response.status === 200) {
          setData(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Initialize ECharts chart
    const myChart: any = document.getElementById("main");
    // eslint-disable-next-line no-unused-expressions
    myChart && myChart.resize();
  }, []);

  const option = {
    title: {
      // text: ``,
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      bottom: 10,
      orient: "horizontal",
      top: "bottom",
      data: data?.map((item: any) => item.storeId), // Map the dummyData to get an array of storeId values
    },
    series: [
      {
        // name: "Access From",
        type: "pie",
        label: {
          show: true,
        },
        radius: "50%",
        data: data?.map((item: any) => ({
          value: (item.revenue / 1.12).toFixed(2),
          name: item.storeId,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <h3 style={{ marginBottom: "10px" }}>
          Revenue Summary {dayjs().format("DD-MM-YYYY")}
        </h3>
        {data?.length > 0 ? (
          <ReactECharts
            option={option}
            style={{ height: "484px", width: "580px" }}
          />
        ) : (
          <div
            style={{
              margin: "50%",
              width: "200px",
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default RevenueSummaryPie;
