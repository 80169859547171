import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
  DatePicker,
  Input,
  Switch,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";
import moment from "moment";
import "./page.css";

const { Option } = Select;

const VariantForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { itemId } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [statesDropdown, setStatesDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDateData, setStartDateData] = useState<any>(null);
  const [endDateData, setEndDateData] = useState<any>(null);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Variant", href: "/variants" },
      {
        label: itemId ? "Edit Variant" : "Add Variant",
        href: itemId ? `/variant/${itemId}` : "/variant/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      // const payLoad = {
      //   companyId: id,
      // };
      // replace endpoint with location id endpoint to get details
      const response = await get(`${apiEndpoints.itemvariantsEdit}/${itemId}`);
      if (response.status === 200) {
        // const initialValues = {
        //   ...response?.data,
        //   active: response?.data?.isActive === 1 ? true : false,
        // };
        const int = response?.data;
        form.setFieldsValue(int);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("/add")) {
      fetchById();
    }
  }, [location]);

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const endPoints = {
        updateDevice: "itemvariantsVariant",
        addDevice: "itemvariantsVariant",
      };
      const endPoint: any = itemId
        ? endPoints.updateDevice
        : endPoints.addDevice;
      const method = itemId ? put : post;
      // const response: any = await method(apiEndpoints[`${endPoint}`], values);
      // Use the correct template literal for constructing the API endpoint
      const apiUrl = itemId
        ? `${apiEndpoints[endPoint]}/${itemId}`
        : apiEndpoints[endPoint];

      // Await the API method with the correct URL and payload
      const response = await method(apiUrl, values);

      if (response.status === 200) {
        notification.success({
          message: itemId ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/variants");
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        message: error?.response?.data?.message || "Error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    message.error("Please fill in all fields");
  };

  //   {
  //     "itemId":12,
  //     "variantName":"Plain SugarCane Juice1",
  //     "variantSize":120,
  //     "variantDesc":"Variant Desc",
  //     "unitPrice":14,
  //     "taxId":1,
  //     "taxInclusive":false,
  //      "variantPhoto":"test.png",
  //      "itemVariantPhotoEComm":"test.png"
  // }
  const [itemsList, setItemsList] = useState([]);

  const fetchItems = async () => {
    try {
      // const payLoad = {
      //   companyId: localStorage.getItem("companyId") || companyId,
      // };
      const response = await get(apiEndpoints.getAllItems);
      if (response.status === 200) {
        setItemsList(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const onTaxChange = (e) => {
    form.setFieldsValue({ taxInclusive: e ? true : false });
  };

  const [taxList, setTaxList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(apiEndpoints.taxlist);
        if (response.status === 200) {
          setTaxList(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };

    fetchData();
    // fetchDd();
  }, []);

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          // className="company-form"
          // style={{ display: "flex", alignItems: "center", gap: "10px" }}
          // labelCol={{ span: 8 }}
        >
          {/* <Row gutter={24}>
            {companyFormMeta?.map((item: any, index: any) => (
              <Col span={8} key={index}>
                <FormBuilder meta={[item]} form={form} />
              </Col>
            ))}
          </Row> */}
          <Row gutter={16}>
            {!location.pathname.includes("/add") && (
              <Col span={8}>
                <Form.Item name="variantId" label="Variant ID">
                  <Input placeholder="Enter Variant ID" disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item
                name="itemId"
                label="Item Name"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select placeholder="Select Item Name">
                  {itemsList?.map((location: any) => (
                    <Option key={location?.itemId} value={location?.itemId}>
                      {location.itemName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="variantName"
                label="Variant Name"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter Variant Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="variantSize"
                label="Variant Size"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter Variant Size" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="variantDesc"
                label="Description"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter Description" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="unitPrice"
                label="Unit Price"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter Unit Price" type="number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="taxId"
                label="Tax"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select placeholder="Select Tax">
                  {taxList?.map((location: any) => (
                    <Option key={location?.taxId} value={location?.taxId}>
                      {location.taxName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="taxInclusive"
                label="Tax Inclusive"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(e) => onTaxChange(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="variantPhoto"
                label="Variant Photo"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter Variant Photo" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="itemVariantPhotoEComm"
                label="Item Variant Photo EComm"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter itemVariantPhotoEComm" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {itemId ? "Update Variant" : "Add Varient"}
            </Button>
            <Button onClick={() => navigate("/variants")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default VariantForm;
