import { Form, Input, Button, Checkbox, Card, Image, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { post } from "../../services/apiService";
import { companyState } from "../../lib/stores/companyId";
import { useRecoilState } from "recoil";
import "./login.css";
import { isLoadingState } from "../../store/loader";

const Login = () => {
  const navigate = useNavigate();
  const [, setCompanyId] = useRecoilState(companyState);
  const [loading, setLoading] = useRecoilState(isLoadingState);

  const onFinish = async (values: {
    username: string;
    password: string;
    remember?: boolean;
  }) => {
    try {
      setLoading(true);
      const rememberMe = values?.remember;
      // delete values.remember;
      if (values?.username !== "" && values?.password !== "") {
        const response = await post("/api/auth/signin", values);
        if (response) {
          navigate("/dashboard");
          notification.success({
            message: `Welcome  ${response?.firstName}`,
          });
          setLoading(false);
          localStorage.setItem("companyId", response.companyId);
          setCompanyId(response.companyId);
          if (rememberMe) {
            localStorage.setItem("user", JSON.stringify(response));
          } else {
            sessionStorage.setItem("user", JSON.stringify(response));
          }
        } else {
          console.log("Sign in failed:", response);
          notification.error({
            message: "Something went wrong",
            description: "Please check your credentials and try again",
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        message: "PLease check your credentials and try again",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        background: " #def5b8",
      }}
    >
      <Card
        className="card-styles"
        style={{
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={"/Juice_logo.png"}
          alt="logo"
          // width={200}
          // height={100}
          preview={false}
        />
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Form.Item>
            <Link to="/forgot-password" style={{ marginTop: "10px" }}>
              Forgot password
            </Link>
          </div>
          <Form.Item>
            <Button
              // loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
