import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  AdminData,
  Company,
  CompanyForm,
  Dashboard,
  Login,
  MobileDevices,
  OrderFailure,
  OrderList,
  OrderRefunds,
  OrderSummary,
  PaymentReport,
  RefundData,
} from "./pages";
import AdminLayout from "./lib/templates/admin-layout";
import Locations from "./pages/Masters/Locations/page";
import LocationForm from "./pages/Masters/Locations/locationForm";
import Users from "./pages/Masters/Users/page";
import UsersForm from "./pages/Masters/Users/usersForm";
import CurrentEvents from "./pages/Masters/CurrentEvents/page";
import EventsForm from "./pages/Masters/CurrentEvents/eventsForm";
import EventDetails from "./pages/Masters/CurrentEvents/EventDetails/page";
import Machines from "./pages/Masters/Machines/page";
import MachinesForm from "./pages/Masters/Machines/machinesForm";
import MachineMap from "./pages/Masters/MachineMap/page";
import MachineMapForm from "./pages/Masters/MachineMap/machinemap";
import PricePlans from "./pages/Masters/PricePlans/page";
import PricePlanForm from "./pages/Masters/PricePlans/PriceplanForm";
import CompanyTypes from "./pages/Masters/CompanyTypes/page";
import SKU from "./pages/Masters/Sku/page";
import CustomerFrom from "./pages/Masters/Company/customer/customerForm";
import Items from "./pages/Masters/Items/page";
import PrivateRoute from "./privateRoute/routeGuard";
import { useEffect } from "react";
import Loader from "./Loader/loader";
import FailureSummary from "./pages/Transactions/Failure-summary/page";
import PreviousEvents from "./pages/Masters/CurrentEvents copy/page";
import Forgot from "./pages/Forgot/forgot";
import Reset from "./pages/Reset/reset";
import UsersManageForm from "./pages/Masters/Users/usersManageForm";
import UsersRolesManage from "./pages/Masters/Users-role-manage/page";
import Variants from "./pages/Masters/Variant/page";
import ItemForm from "./pages/Masters/Items/itemForm";
import Menus from "./pages/Menu/menu/page";
import MenuForm from "./pages/Menu/menu/itemForm";
import MenuItem from "./pages/Menu/menu-item-price/page";
import MenuItemForm from "./pages/Menu/menu-item-price/itemForm";
import OutletGroup from "./pages/Masters/OutletGroup/page";
import OutletGroupForm from "./pages/Masters/OutletGroup/itemForm";
import NotFound from "./pages/NotFound/page";
import StoreMenuPriceForm from "./pages/Menu/menu-item-price/itemForm";
import MenuToStoreForm from "./pages/Menu/menu-to-store/itemForm";
import MenuToStore from "./pages/Menu/menu-to-store/page";
import MenuItemPriceForm from "./pages/Menu/menu-item-price/itemForm";
import VariantForm from "./pages/Masters/Variant/variantForm";

// Define route configuration type
interface RouteConfig {
  path: string;
  element: React.ReactNode;
  children?: RouteConfig[];
}

// Group routes by feature
const dashboardRoutes: RouteConfig[] = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
];
// Group routes by feature
const reportsRoutes: RouteConfig[] = [
  {
    path: "/order-summary",
    element: <OrderSummary />,
  },
  {
    path: "/order-list",
    element: <OrderList />,
  },
  {
    path: "/order-failure",
    element: <OrderFailure />,
  },
  {
    path: "/order-refund",
    element: <OrderRefunds />,
  },
  {
    path: "/payment-reports",
    element: <PaymentReport />,
  },
  {
    path: "/failure-summary",
    element: <FailureSummary />,
  },
  {
    path: "/refund-data",
    element: <RefundData />,
  },

  // ... other dashboard related routes
];

const adminRoutes: RouteConfig[] = [
  {
    path: "/admin-data",
    element: <AdminData />,
  },
  {
    path: "/refund",
    element: <RefundData />,
  },
  {
    path: "/mobile-devices",
    element: <MobileDevices />,
  },
];

const masterRoutes: RouteConfig[] = [
  {
    path: "/company",
    element: <Company />,
  },
  {
    path: "/company/add",
    element: (
      // CompanyForm />,
      <CustomerFrom />
    ),
  },
  {
    path: "/company/:id",
    element: <CompanyForm />,
  },
  {
    path: "/company/editCompany/:id",
    element: <CustomerFrom />,
  },
  {
    path: "/locations",
    element: <Locations />,
  },
  {
    path: "/locations/add",
    element: <LocationForm />,
  },
  {
    path: "/locations/editLocation/:id",
    element: <LocationForm />,
  },
  {
    path: "/machines",
    element: <Machines />,
  },
  {
    path: "/machines/add",
    element: <MachinesForm />,
  },
  {
    path: "/machines/editMachines/:machineId",
    element: <MachinesForm />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/users/add",
    element: <UsersForm />,
  },
  {
    path: "/users/edit/:id",
    element: <UsersForm />,
  },
  {
    path: "/users/manage/:id",
    element: <UsersRolesManage />,
  },
  {
    path: "/users/manage/add/:id",
    element: <UsersManageForm />,
  },
  {
    path: "/item",
    element: <Items />,
  },
  {
    path: "/items/item/:itemId",
    element: <ItemForm />,
  },
  {
    path: "/items/edititem/:itemId",
    element: <ItemForm />,
  },
  // /item/add
  {
    path: "/item/add",
    element: <ItemForm />,
  },
  {
    path: "/variants",
    element: <Variants />,
  },
  {
    path: "/variant/add",
    element: <VariantForm />,
  },
  {
    path: "/variant/:itemId",
    element: <VariantForm />,
  },
  {
    path: "/previous-events",
    element: <PreviousEvents />,
  },
  {
    path: "/current-events",
    element: <CurrentEvents />,
  },
  {
    path: "/current-events/add",
    element: <EventsForm />,
  },
  {
    path: "/event-details/:id",
    element: <EventsForm />,
  },
  {
    path: "/machine-map",
    element: <MachineMap />,
  },
  {
    path: "/machine-map/add",
    element: <MachineMapForm />,
  },
  {
    path: "/edit-machine-map/:id",
    element: <MachineMapForm />,
  },
  {
    path: "/current-events/add",
    element: <EventsForm />,
  },
  // /price-plans
  {
    path: "/price-plans",
    element: <PricePlans />,
  },
  {
    path: "/price-plans/add",
    element: <PricePlanForm />,
  },
  {
    path: "/company-types",
    element: <CompanyTypes />,
  },
  {
    path: "/sku",
    element: <SKU />,
  },
  {
    path: "/menu",
    element: <Menus />,
  },
  {
    path: "/menu/add",
    element: <MenuForm />,
  },
  {
    path: "/menu/edit/:itemId",
    element: <MenuForm />,
  },
  {
    path: "/menu-item-price/:menuId",
    element: <MenuItem />,
  },
  {
    path: "/menu-item-price/add",
    element: <MenuItemPriceForm />,
  },
  {
    path: "/outlet-group",
    element: <OutletGroup />,
  },
  {
    path: "/outlet-group/add",
    element: <OutletGroupForm />,
  },
  {
    path: "/outlet-group/:itemId",
    element: <OutletGroupForm />,
  },
  {
    path: "/store-menu-price",
    element: <MenuToStore />,
  },
  {
    path: "/store-menu-price/add",
    element: <MenuToStoreForm />,
  },
  {
    path: "/store-menu-price/:storeId/:menuId",
    element: <MenuToStoreForm />,
  },
];

// Create a wrapper component for protected routes with AdminLayout
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => (
  <PrivateRoute>
    <AdminLayout>{children}</AdminLayout>
  </PrivateRoute>
);

// Render routes recursively
const renderRoutes = (routes: RouteConfig[]) => {
  return routes?.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        route.path === "/" ||
        route.path === "/login" ||
        route.path.includes("forgot") ||
        route.path.includes("reset") ? (
          route.element
        ) : (
          <ProtectedRoute>{route.element}</ProtectedRoute>
        )
      }
    >
      {route.children && renderRoutes(route.children)}
    </Route>
  ));
};

const App = () => {
  return (
    <>
      <Loader />
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset_password" element={<Reset />} />

          {/* Protected routes */}
          {renderRoutes(dashboardRoutes)}
          {renderRoutes(reportsRoutes)}
          {renderRoutes(adminRoutes)}
          {renderRoutes(masterRoutes)}

          {/* 404 route */}
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <NotFound />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
