import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Tabs,
  Upload,
  message,
  Radio,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { get, post, put } from "../../../../services/apiService";
import getApiEndpoints from "../../../../services/endpoints";
import { useRecoilState } from "recoil";
import { companyState } from "../../../../lib/stores/companyId";
import { useNavigate, useParams } from "react-router-dom";
import { breadcrumbState } from "../../../../lib/stores/breadcrumb";
import "./page.scss";
import axios from "axios";
import { filterOption } from "../../../../lib/utils/common-formats";
import {
  maxLengthValidator,
  minLengthValidator,
  phoneNumberValidator,
} from "../../../../lib/utils/form-validator";

const { Option } = Select;
const { TabPane } = Tabs;

const CustomerFrom = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [statesDropdown, setStatesDropdown] = useState([]);
  const [parentCompanyDropdown, setParentCompanyDropdown] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  console.log(statesDropdown, "statesDropdown");
  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>();
  console.log(data);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const { id } = useParams();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [parentId, setParentId] = useState<any>();
  const [states, setStates] = useState([]);
  const [statesShip, setstatesShip] = useState([]);

  const handleCountryChange = (value) => {
    const selectedCountry = countryData.find(
      (country) => country.name === value
    );
    setStates(selectedCountry ? selectedCountry.states : []);
  };

  const handleCountryChangeShip = (value) => {
    const selectedCountry = countryData.find(
      (country) => country.name === value
    );
    setstatesShip(selectedCountry ? selectedCountry.states : []);
  };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/company" },
      { label: "Company", href: "/company" },
      {
        label: id ? "Edit Company" : "Add Company",
        href: id ? `/company/${id}` : "/company/add",
      },
    ]);
  }, [setBreadCrumb]);

  const [countryData, setCountryData] = useState([]);
  const [editDataCheck, setEditDataCheck] = useState(false);

  const checkEditStatus = (response: any) => {
    const { companyMasterReqData, companyAddressList } = response?.data || {};

    let edit = false;

    // Check if any key in companyMasterReqData has a value
    if (companyMasterReqData && typeof companyMasterReqData === "object") {
      for (let key in companyMasterReqData) {
        if (companyMasterReqData[key]) {
          edit = true;
          break;
        }
      }
    }

    // If edit is still false, check if any object in companyAddressList has a value
    if (!edit && Array.isArray(companyAddressList)) {
      for (let obj of companyAddressList) {
        for (let key in obj) {
          if (obj[key]) {
            edit = true;
            break;
          }
        }
        if (edit) break;
      }
    }

    console.log("Edit:", edit);
    return edit;
  };

  const validateGSTIN = (rule, value) => {
    if (!value) return Promise.resolve();
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][0-9A-Z][Z][0-9A-Z]$/;
    return gstinRegex.test(value)
      ? Promise.resolve()
      : Promise.reject("Invalid GSTIN format");
  };

  const handleGSTINChange = (e) => {
    const gstValue = e.target.value;
    console.log(gstValue, "panpan");
    // if (gstValue?.length === 15) {
    const pan = gstValue.substring(2, 12); // Extract PAN from GSTIN

    form.setFieldsValue({ companyPAN: pan });
    // }
  };

  const getCompanyDetails = async () => {
    try {
      // const payLoad = {
      //   companyId: id,
      // };
      const response: any = await get(
        apiEndpoints.getCompanyDetailsById + `/${id}`
      );
      if (response.status === 200) {
        checkEditStatus(response?.data);
        setEditDataCheck(checkEditStatus(response?.data));
        const initialValues = {
          ...response?.data?.companyMasterReqData,
          active:
            response?.data?.companyMasterReqData?.isActive === 1 || true
              ? true
              : false,
        };
        handleParent(response?.data?.companyMasterReqData?.companyMasterId);
        form.setFieldValue(
          "billingAddress.country",
          response?.data?.companyAddressList[0]?.companyAddressCountry
        );
        form.setFieldValue(
          "billingAddress.state",
          response?.data?.companyAddressList[0]?.companyAddressState
        );
        form.setFieldValue(
          "billingAddress.city",
          response?.data?.companyAddressList[0]?.companyAddressCity
        );
        form.setFieldValue(
          "billingAddress.detailAddress",
          response?.data?.companyAddressList[0]?.companyAddressStreet1
        );
        form.setFieldValue(
          "billingAddress.landmark",
          response?.data?.companyAddressList[0]?.companyAddressStreet2
        );
        form.setFieldValue(
          "billingAddress.zipcode",
          response?.data?.companyAddressList[0]?.companyAddressZipCode
        );
        form.setFieldValue(
          "billingAddress.fax",
          response?.data?.companyAddressList[0]?.companyAddressFax
        );
        form.setFieldValue(
          "billingAddress.personName",
          response?.data?.companyAddressList[0]?.companyAddressAttention
        );
        form.setFieldValue(
          "billingAddress.phone",
          response?.data?.companyAddressList[0]?.companyAddressPhone
        );

        form.setFieldValue(
          "shippingAddress.country",
          response?.data?.companyAddressList[1]?.companyAddressCountry
        );
        form.setFieldValue(
          "shippingAddress.state",
          response?.data?.companyAddressList[1]?.companyAddressState
        );
        form.setFieldValue(
          "shippingAddress.city",
          response?.data?.companyAddressList[1]?.companyAddressCity
        );
        form.setFieldValue(
          "shippingAddress.detailAddress",
          response?.data?.companyAddressList[1]?.companyAddressStreet1
        );
        form.setFieldValue(
          "shippingAddress.landmark",
          response?.data?.companyAddressList[1]?.companyAddressStreet2
        );
        form.setFieldValue(
          "shippingAddress.zipcode",
          response?.data?.companyAddressList[1]?.companyAddressZipCode
        );
        form.setFieldValue(
          "shippingAddress.fax",
          response?.data?.companyAddressList[1]?.companyAddressFax
        );
        form.setFieldValue(
          "shippingAddress.personName",
          response?.data?.companyAddressList[1]?.companyAddressAttention
        );
        form.setFieldValue(
          "shippingAddress.phone",
          response?.data?.companyAddressList[1]?.companyAddressPhone
        );

        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("editCompany")) {
      // fetchById();
      getCompanyDetails();
    }
  }, [location]);

  useEffect(() => {
    // const payLoad = {
    //   companyId: localStorage.getItem("companyId") || companyId,
    // };
    const fetchData = async () => {
      try {
        const response = await get(apiEndpoints.getListOfCompaniesUnderId);
        if (response.status === 200) {
          setParentCompanyDropdown(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    // country state api
    const fetchCountryState = async () => {
      try {
        const response = await axios.get(
          "https://countriesnow.space/api/v0.1/countries/states"
        );
        console.log(response, "response-conutnry");

        if (response.status === 200) {
          setCountryData(response?.data?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };

    fetchData();
    fetchCountryState();
    // fetchDd();
  }, []);

  //   useEffect(async () => {
  //     const dropdownResponse = await get(getApiEndpoints.getAllStates);
  //     if (dropdownResponse.status === 200) {
  //       setStatesDropdown(dropdownResponse?.data);
  //     }
  //   }, []);
  // Function to copy billing address to shipping address
  const handleCopyAddress = () => {
    form.setFieldsValue({
      "shippingAddress.country": form.getFieldValue("billingAddress.country"),
      "shippingAddress.detailAddress": form.getFieldValue(
        "billingAddress.detailAddress"
      ),
      "shippingAddress.city": form.getFieldValue("billingAddress.city"),
      "shippingAddress.state": form.getFieldValue("billingAddress.state"),
      "shippingAddress.zipcode": form.getFieldValue("billingAddress.zipcode"),
      "shippingAddress.phone": form.getFieldValue("billingAddress.phone"),
      "shippingAddress.fax": form.getFieldValue("billingAddress.fax"),
      "shippingAddress.personName": form.getFieldValue(
        "billingAddress.personName"
      ),
      "shippingAddress.landmark": form.getFieldValue("billingAddress.landmark"),
    });
  };

  const onFinish = async (values) => {
    const payLoad = {
      companyId: id ? localStorage.getItem("companyId") || companyId : "",
      companyMasterReqData: {
        companyMasterId: values?.companyMasterId,
        companyTypeId: values?.companyTypeId,
        businessType: values?.businessType,
        companyName: values?.companyName,
        customerDisplayName: values?.customerDisplayName,
        companyEmail: values?.companyEmail,
        primaryContactSalutation: values?.primaryContactSalutation,
        primaryContactFirstName: values?.primaryContactFirstName,
        primaryContactLastName: values?.primaryContactLastName,
        companyPhone: values?.companyPhone,
        companyMobile: values?.companyMobile, // no key
        active: values?.active === 1 ? true : false,
        companyGST: values?.companyGST,
        // other details
        companyPAN: values?.companyPAN,
        currency: values?.currency, // no key
        openingBalance: values?.openingBalance, // no key
        paymentTerms: values?.paymentTerms,
        enablePortal: values?.enablePortal,
        portalLanguage: values?.portalLanguage,
        // no use of below keys - so removed from payload
        // companyAddress: values?.companyAddress || "testttttttttt", // this field not present in ui
        // companyAddress: "H No 123/45",
        // stateName: values?.companyAddress || "Telanaga",
        // country: values?.country || "India",
        // postalCode: values?.postalCode || "400010",
        // contact persons
        companyContactSalutation: values?.contactPerson?.salutation, // no key
        companyContactFirstName: values?.contactPerson?.firstName, // no key
        companyContactLastName: values?.contactPerson?.lastName, // no key
        companyContactEmail: values?.contactPerson?.email, // no key
        companyContactPhone: values?.contactPerson?.phone,
        companyContactMobile: values?.contactPerson?.workPhone,
        companyContactDesignation: values?.contactPerson?.designation, // no key
        companyContactDepartment: values?.contactPerson?.department, // no key
        // remarks
        companyRemarks: values?.companyRemarks,
        // no use of below keys
        // companyAddress: "H No 123/45",
        // stateName: "Telangana",
        // country: "India",
        // postalCode: "500021",
      },
      // address
      companyAddressList: [
        {
          companyAddressType: "Billing",
          companyAddressCountry: values["billingAddress.country"],
          companyAddressState: values["billingAddress.state"],
          companyAddressCity: values["billingAddress.city"],
          companyAddressStreet1: values["billingAddress.detailAddress"],
          companyAddressStreet2: values["billingAddress.landmark"],
          companyAddressZipCode: values["billingAddress.zipcode"],
          companyAddressPhone: values["billingAddress.phone"],
          companyAddressFax: values["billingAddress.fax"],
          companyAddressAttention: values["billingAddress.personName"],
        },
        {
          companyAddressType: "Shipping",
          companyAddressCountry: values["shippingAddress.country"],
          companyAddressState: values["shippingAddress.state"],
          companyAddressCity: values["shippingAddress.city"],
          companyAddressStreet1: values["shippingAddress.detailAddress"],
          companyAddressStreet2: values["shippingAddress.landmark"],
          companyAddressZipCode: values["shippingAddress.zipcode"],
          companyAddressPhone: values["shippingAddress.phone"],
          companyAddressFax: values["shippingAddress.fax"],
          companyAddressAttention: values["shippingAddress.personName"],
        },
      ],
    };
    if (!id) {
      delete payLoad?.companyId;
    }
    try {
      const endPoints: any = {
        updateCompany: "updateCompanyMaster",
        addCompany: "addCompanyMaster",
      };
      const endPoint: any = id ? endPoints.updateCompany : endPoints.addCompany;
      const method = id ? put : post;
      const response: any = await method(apiEndpoints[`${endPoint}`], payLoad);
      if (response.status === 200) {
        // setCompanyTypes(response?.data);
        navigate("/company");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleParent = async (e) => {
    setParentId(e);
    try {
      const payLoad = {
        companyId: e,
      };
      const response = await post(
        apiEndpoints.getCompanyTypesUnderCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setCompanyTypes(response?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    // Check if companyName is the field being changed
    if ("companyName" in changedValues) {
      // Update customerDisplayName with the same value
      form.setFieldsValue({
        customerDisplayName: changedValues.companyName,
      });
    }
  };

  return (
    <div className="company-form">
      <Form
        onValuesChange={handleFormValuesChange}
        form={form}
        // layout="horizontal"
        onFinish={onFinish}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        layout="horizontal"
      >
        {location.pathname.includes("editCompany") && (
          <div>
            <label>Company ID:</label>
            <Form.Item name="companyId">
              <Input disabled style={{ width: " 32%", marginTop: "10px" }} />
            </Form.Item>
          </div>
        )}
        <Form.Item>
          <Input.Group
            compact
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ marginBottom: "10px" }}>Parent Company</label>
              <Form.Item
                name={["companyMasterId"]}
                noStyle
                rules={[
                  { required: true, message: "parentCompany is required" },
                ]}
              >
                <Select
                  placeholder="Parent Company"
                  onSelect={handleParent}
                  style={{ width: "210px" }}
                >
                  {parentCompanyDropdown?.map((item) => (
                    <Option key={item.companyId} value={item.companyId}>
                      {item.companyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 10px",
              }}
            >
              <label style={{ marginBottom: "10px" }}>Company Type</label>
              <Form.Item
                name={["companyTypeId"]}
                noStyle
                rules={[
                  { required: true, message: "company Type is required" },
                ]}
              >
                <Select placeholder="Company Type" style={{ width: "210px" }}>
                  {companyTypes?.map((item) => (
                    <Option key={item.companyTypeId} value={item.companyTypeId}>
                      {item.companyType}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{
                margin: "0 10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Business Type</label>
              <Form.Item name={["businessType"]}>
                <Radio.Group style={{ marginTop: "10px" }}>
                  <Radio value="Business"> Business </Radio>
                  <Radio value="Individual"> Individual </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <Input.Group
            compact
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ marginBottom: "10px" }}>Company Name</label>
              <Form.Item
                name={["companyName"]}
                noStyle
                rules={[{ required: true, message: "companyName is required" }]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 10px",
              }}
            >
              <label style={{ marginBottom: "10px" }}>
                Customer Display Name
              </label>
              <Form.Item
                name={["customerDisplayName"]}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Customer Display Name is required",
                  },
                ]}
              >
                <Input placeholder="Customer Display Name" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 10px",
              }}
            >
              <label style={{ marginBottom: "10px" }}>Company Email</label>
              <Form.Item name={["companyEmail"]}>
                <Input placeholder="Company Email" />
              </Form.Item>
            </div>
          </Input.Group>
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ width: "21%", marginRight: "10px" }}>
              Primary Contact:
            </label>
            <Input.Group compact>
              <Form.Item
                name={["primaryContactSalutation"]}
                noStyle
                // rules={[{ required: true, message: "Salutation is required" }]}
              >
                <Select
                  placeholder="Salutation"
                  style={{
                    width: "12%",
                    borderRadius: "6px",
                    marginRight: "20px",
                  }}
                >
                  <Option value="Mr">Mr.</Option>
                  <Option value="Mrs">Mrs.</Option>
                  <Option value="Ms">Ms.</Option>
                  <Option value="Miss">Miss</Option>
                  <Option value="Dr">Dr.</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={["primaryContactFirstName"]}
                noStyle
                // rules={[{ required: true, message: "First name is required" }]}
              >
                <Input
                  placeholder="First Name"
                  style={{
                    width: "25%",
                    borderRadius: "6px",
                    marginRight: "20px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["primaryContactLastName"]}
                noStyle
                // rules={[{ required: true, message: "Last name is required" }]}
              >
                <Input
                  placeholder="Last Name"
                  style={{ width: "25%", borderRadius: "6px" }}
                />
              </Form.Item>
            </Input.Group>
          </div>
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ width: "20%", marginRight: "10px" }}>
              Company Phone:
            </label>
            <Input.Group compact>
              <Form.Item
                // type="number"
                name={["companyPhone"]}
                noStyle
                rules={[
                  // {
                  //   required: true,
                  //   message: "Work Phone is required",
                  // },
                  {
                    min: 10,
                    message: "Work Phone must be exactly 10 characters",
                  },
                  {
                    max: 10,
                    message: "Work Phone must be exactly 10 characters",
                  },
                ]}
                // rules={[{ required: true, message: "Work Phone is required" }]}
                // rules={[
                //   {
                //     validator: async (value: any) => {
                //       return phoneNumberValidator(value);
                //     },
                //   },
                //   {
                //     validator: async (value: any) => {
                //       return maxLengthValidator("Number", 10, value);
                //     },
                //   },
                //   {
                //     validator: async (value: any) => {
                //       return minLengthValidator("Number", 10, value);
                //     },
                //   },
                // ]}
              >
                <Input
                  // type="number"
                  placeholder="Work Phone"
                  style={{
                    width: "25%",
                    borderRadius: "6px",
                    marginRight: "10px",
                  }}
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                name={["companyMobile"]}
                noStyle
                rules={[
                  // {
                  //   required: true,
                  //   message: "Company Mobile is required",
                  // },
                  {
                    min: 10,
                    message: "Company Mobile must be exactly 10 characters",
                  },
                  {
                    max: 10,
                    message: "Company Mobile must be exactly 10 characters",
                  },
                ]}
                // rules={[{ required: true, message: "Mobile is required" }]}
                // rules={[
                //   {
                //     validator: async (value: any) => {
                //       return phoneNumberValidator(value);
                //     },
                //   },
                //   {
                //     validator: async (value: any) => {
                //       return maxLengthValidator("Number", 10, value);
                //     },
                //   },
                //   {
                //     validator: async (value: any) => {
                //       return minLengthValidator("Number", 10, value);
                //     },
                //   },
                // ]}
              >
                <Input
                  // type="number"
                  placeholder="Mobile"
                  style={{
                    width: "25%",
                    margin: "0 10px",
                    borderRadius: "6px",
                  }}
                  maxLength={10}
                />
              </Form.Item>
            </Input.Group>
          </div>
        </Form.Item>
        <Form.Item
          name="active"
          valuePropName="checked"
          // style={{ marginLeft: "95px" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ marginRight: "10px", width: "16%" }}>Status:</label>
            <Switch
              defaultChecked={true}
              // checked={"Active"}
              checkedChildren="Active"
              unCheckedChildren="InActive"
              onChange={(checked) => {
                // Convert the boolean to '1' or '0' and set the form field value
                form.setFieldsValue({ active: checked ? 1 : 0 });
              }}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="companyGST"
          rules={[
            // { required: true, message: "Company GST is required" },
            { min: 15, message: "GSTIN must be 15 characters" },
            { max: 15, message: "GSTIN must be 15 characters" },
            { validator: validateGSTIN },
          ]}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ marginRight: "10px", width: "16%" }}>
              Company GST:
            </label>
            <Input
              maxLength={15}
              placeholder="Company GST"
              style={{
                width: "20%",
                borderRadius: "6px",
              }}
              onChange={handleGSTINChange}
            />
          </div>
        </Form.Item>
        {/* Tabs Section */}
        <Tabs defaultActiveKey="1">
          <TabPane tab="Other Details" key="1">
            {/* Other Details Form Items */}
            <div>
              <Form.Item
                name="companyPAN"
                label="PAN"
                rules={[
                  {
                    min: 10,
                    message: "Company PAN must be exactly 10 characters",
                  },
                  {
                    max: 10,
                    message: "Company PAN must be exactly 10 characters",
                  },
                ]}
              >
                <Input
                  maxLength={10}
                  placeholder="PAN Number"
                  style={{ width: "20%" }}
                />
              </Form.Item>
              <Form.Item name="currency" label="Currency">
                <Select placeholder="Select currency" style={{ width: "20%" }}>
                  <Option value="INR">INR</Option>
                  <Option value="USD">USD</Option>
                  <Option value="EUR">EUR</Option>
                  {/* Add other currencies as needed */}
                </Select>
              </Form.Item>
              <Form.Item name="openingBalance" label="Opening Balance">
                <Input
                  prefix="₹"
                  type="number"
                  placeholder="Opening Balance"
                  style={{ width: "20%" }}
                />
              </Form.Item>
              <Form.Item name="paymentTerms" label="Payment Terms">
                <Select placeholder="Payment Terms" style={{ width: "20%" }}>
                  <Option value="net20">Net 20 Days</Option>
                  <Option value="net40">Net 40 Days</Option>
                  {/* Add other terms as needed */}
                </Select>
              </Form.Item>
              <Form.Item
                name="enablePortal"
                valuePropName="checked"
                label="Enable Portal"
              >
                <Checkbox>Allow Portal Access to this Customer</Checkbox>
              </Form.Item>
              <Form.Item name="portalLanguage" label="Portal Language">
                <Select placeholder="Portal Language" style={{ width: "20%" }}>
                  <Option value="English">English</Option>
                  <Option value="Spanish">Spanish</Option>
                  {/* Add other languages as needed */}
                </Select>
              </Form.Item>
              {/* <Form.Item name="documents" label="Documents Upload">
              <Upload>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item> */}
            </div>
          </TabPane>
          <TabPane tab="Address" key="2">
            {/* Billing Address */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flexBasis: "48%" }}>
                <h3>Billing Address</h3>
                <Form.Item name="billingAddress.country" label="Country">
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="Select country"
                    style={{ width: "40%" }}
                    onChange={handleCountryChange}
                  >
                    <Option key="IN" value="India">
                      India
                    </Option>
                    {countryData
                      .filter((country) => country.name !== "India")
                      ?.map((country) => (
                        <Option key={country.iso2} value={country.name}>
                          {country.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="billingAddress.state" label="State">
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="State"
                    style={{ width: "40%" }}
                  >
                    {states?.map((state) => (
                      <Option key={state.state_code} value={state.name}>
                        {state.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="billingAddress.city" label="City">
                  <Input placeholder="City" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item
                  name="billingAddress.detailAddress"
                  label="Detail Address"
                >
                  <Input
                    placeholder="Detail Address"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="billingAddress.landmark" label="Land Mark">
                  <Input
                    placeholder="Enter Land Mark"
                    style={{ width: "40%" }}
                  />
                </Form.Item>

                <Form.Item
                  name="billingAddress.zipcode"
                  label="Zipcode/Pincode"
                >
                  <Input
                    placeholder="Zipcode/Pincode"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="billingAddress.phone" label="Phone Number">
                  <Input placeholder="Phone Number" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item name="billingAddress.fax" label="Fax Number">
                  <Input
                    placeholder="Fax Number"
                    style={{ width: "40%" }}
                    maxLength={10}
                  />
                </Form.Item>
                <Form.Item name="billingAddress.personName" label="Person Name">
                  <Input placeholder="Enter Name" style={{ width: "40%" }} />
                </Form.Item>
              </div>
              {/* Shipping Address */}
              <div style={{ flexBasis: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h3>Shipping Address &nbsp;</h3>(
                  <Button
                    type="link"
                    onClick={handleCopyAddress}
                    style={{ padding: 0 }}
                  >
                    Copy Billing Address to Shipping Address
                  </Button>
                  )
                </div>
                <Form.Item
                  name="shippingAddress.country"
                  label="Country/Region"
                >
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="Country/Region"
                    style={{ width: "40%" }}
                    onChange={handleCountryChangeShip}
                  >
                    <Option key="IN" value="India">
                      India
                    </Option>
                    {countryData
                      .filter((country) => country.name !== "India")
                      ?.map((country) => (
                        <Option key={country.iso2} value={country.name}>
                          {country.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="shippingAddress.state" label="State">
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="State"
                    style={{ width: "40%" }}
                  >
                    {statesShip?.map((state) => (
                      <Option key={state.state_code} value={state.name}>
                        {state.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="shippingAddress.city" label="City">
                  <Input placeholder="City" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item
                  name="shippingAddress.detailAddress"
                  label="Detail Address"
                >
                  <Input
                    placeholder="Detail Address"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="shippingAddress.landmark" label="Land Mark">
                  <Input
                    placeholder="Enter Land Mark"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item
                  name="shippingAddress.zipcode"
                  label="Zipcode/Pincode"
                >
                  <Input
                    placeholder="Zipcode/Pincode"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="shippingAddress.phone" label="Phone Number">
                  <Input placeholder="Phone Number" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item name="shippingAddress.fax" label="Fax Number">
                  <Input
                    placeholder="Fax Number"
                    style={{ width: "40%" }}
                    maxLength={10}
                  />
                </Form.Item>
                <Form.Item
                  name="shippingAddress.personName"
                  label="Person Name"
                >
                  <Input placeholder="Enter Name" style={{ width: "40%" }} />
                </Form.Item>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Contact Persons" key="3">
            {/* Contact Persons Form Items */}
            <Form.Item label="Contact Person">
              <Input.Group compact>
                <Form.Item
                  name={["contactPerson", "salutation"]}
                  noStyle
                  // rules={[
                  //   { required: true, message: "Salutation is required" },
                  // ]}
                >
                  <Select
                    placeholder="Select Salutation"
                    style={{ width: "10%" }}
                  >
                    <Option value="Mr">Mr.</Option>
                    <Option value="Mrs">Mrs.</Option>
                    <Option value="Ms">Ms.</Option>
                    <Option value="Miss">Miss</Option>
                    <Option value="Dr">Dr.</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["contactPerson", "firstName"]}
                  noStyle
                  // rules={[
                  //   { required: true, message: "First name is required" },
                  // ]}
                >
                  <Input
                    placeholder="First Name"
                    style={{
                      width: "20%",
                      margin: "0 10px",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["contactPerson", "lastName"]}
                  noStyle
                  // rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input
                    placeholder="Last Name"
                    style={{ width: "20%", borderRadius: "6px" }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name={["contactPerson", "email"]}
              label="Email Address"
              // rules={[{ required: true, type: "email" }]}
            >
              <Input placeholder="Enter Email" style={{ width: "20%" }} />
            </Form.Item>
            <Form.Item
              name={["contactPerson", "phone"]}
              label="Phone"
              rules={[
                {
                  min: 10,
                  message: "Phone must be exactly 10 characters",
                },
                {
                  max: 10,
                  message: "Phone must be exactly 10 characters",
                },
              ]}
            >
              <Input
                maxLength={10}
                placeholder="Enter phone number"
                style={{ width: "20%" }}
              />
            </Form.Item>
            <Form.Item label="Customer Person">
              <Input.Group compact>
                <Form.Item
                  name={["contactPerson", "workPhone"]}
                  noStyle
                  // rules={[
                  //   { required: true, message: "Work Phone is required" },
                  // ]}
                >
                  <Input
                    placeholder="Enter work phone number"
                    style={{
                      width: "20%",

                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["contactPerson", "mobile"]}
                  noStyle
                  // rules={[{ required: true, message: "Mobile is required" }]}
                >
                  <Input
                    placeholder="Enter mobile number"
                    style={{
                      width: "20%",
                      margin: "0 10px",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name={["contactPerson", "designation"]}
              label="Designation"
            >
              <Input placeholder="Enter designation" style={{ width: "20%" }} />
            </Form.Item>
            <Form.Item
              name={["contactPerson", "department"]}
              label="Department"
            >
              <Input placeholder="Enter department" style={{ width: "20%" }} />
            </Form.Item>
          </TabPane>
          <TabPane tab="Remarks" key="4">
            {/* Remarks */}
            <Form.Item name="companyRemarks" label="Remarks">
              <Input.TextArea
                placeholder="Enter remarks"
                style={{ width: "40%" }}
              />
            </Form.Item>
          </TabPane>
        </Tabs>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CustomerFrom;
