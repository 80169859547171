import { Divider, Image } from "antd";
import "./card.css";

export const CardContainer = ({ data }: any) => {
  const formatNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(number);

    return formattedNumber;
  };

  const normalFormatNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN").format(number);

    return formattedNumber;
  };

  const cardDatas = [
    {
      device: false,
      color: "#fcd9e0",
      icon: "/orders-icon.webp",
      headerText: "Orders",
      today: normalFormatNumber(data?.noOfTodayOrders) || 0,
      total: normalFormatNumber(data?.noOfTotalOrders) || 0,
    },
    {
      device: false,
      color: "#b6e9fa",
      icon: "/glass.png",
      headerText: "Glasses",
      today: normalFormatNumber(data?.noOfTodayCups) || 0,
      total: normalFormatNumber(data?.noOfTotalCups) || 0,
    },
    // parseFloat((record?.amount / 1.12).toFixed(2))
    {
      device: false,
      color: "#faf1b6",
      icon: "/revenue.png",
      headerText: "Revenue",
      today: data?.todayRevenue
        ? formatNumber((data?.todayRevenue / 1.12).toFixed(2))
        : 0,
      total: data?.totalRevenue
        ? formatNumber((data?.totalRevenue / 1.12).toFixed(2))
        : 0,
    },
    {
      device: false,
      color: "#fad1b6",
      icon: "/failtran.png",
      headerText: "Failed",
      today: normalFormatNumber(data?.noOfFailed) || 0,
      total: normalFormatNumber(data?.totalNoOfFailed) || 0,
    },
    {
      device: true,
      color: "#faf8b6",
      icon: "/device.png",
      headerText: "Devices   ",
      today: normalFormatNumber(data?.noOfDevices) || 0,
      total: normalFormatNumber(data?.noOfStores) || 0,
    },
  ];

  return (
    <div className="card-flex">
      {cardDatas?.map((data, index) => (
        <CardItem key={index} idNum={index + 1} cardData={data} />
      ))}
    </div>
  );
};

const CardContent = ({ cardData }: any) => (
  <div className="card-flex-wrapper" style={{ background: cardData.color }}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <label className="card-header-text">{cardData.headerText}</label>
      <Image
        src={cardData.icon}
        alt="order-icon"
        preview={false}
        width={52}
        height={52}
        style={{ padding: "5px" }}
      />
    </div>
    <Divider style={{ margin: 0 }} />
    <div className="card-flex-content">
      <p style={{ fontSize: "18px", fontWeight: 500 }}>
        {cardData.device ? "Stores" : "Total"}
        <span style={{ float: "right", fontSize: "18px", fontWeight: 700 }}>
          {cardData.total}
        </span>
      </p>
      <p style={{ fontSize: "16px", fontWeight: 500 }}>
        {cardData.device ? "Machines" : "Today"}
        <span style={{ float: "right", fontSize: "18px", fontWeight: 700 }}>
          {cardData.today}
        </span>
      </p>
    </div>
  </div>
);

const CardItem = ({ idNum, cardData }: any) => (
  <div id={`card-${idNum}`} className="card-flex-item">
    <CardContent cardData={cardData} />
  </div>
);
